import { createPortal } from 'react-dom';

type PortalProps = {
  /** id of the target DOM element */
  portalId: string;
  /** the react node to be injected into the target DOM element */
  children: React.ReactNode;
};

/**
 * injects the children into a target DOM element
 *
 * does nothing and returns null if the target DOM element is not found
 */
export default function Portal({
  portalId,
  children,
}: PortalProps): React.ReactPortal | null {
  const portalTarget = document.getElementById(portalId);
  if (!portalTarget) {
    return null;
  }
  return createPortal(children, portalTarget);
}
